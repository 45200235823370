import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useNavigate} from 'react-router-dom'
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import './Home.css'

function Packages() {
   const location = useLocation();
   const [Packagestoweb, setPackagestoweb] = useState([]);
   const nav = useNavigate();
   


  useEffect(() => {
    packagedata1();
}, []);

const packagedata1 = async () => {
  const colRef = collection(db, "packages");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map(doc => doc.data());
      setPackagestoweb(docs);
}


    return (
       <div className="m-5">
       <CardGroup >
   {Packagestoweb.filter(e=>e.ToCountry.toUpperCase()==location.search.slice(1,100).replace(/%20/gi," ").toUpperCase()).map(packageele => ( 
                    <Card className='mx-3 '>
                    <Card.Img variant="top" className='in' src={packageele.uploadThumbURL} />
                    <Card.Body>
                     <a href=''><Card.Title className='text-start text-dark fw-bold' hrf='' onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/package",
                                          search:packageele.id
                                        });
                                      
                                    }
                                }>{packageele.packageName}</Card.Title></a>
                                <div className=''>
                      <div className="mx-1 ">
                      <p className='text-danger text-start'>
                      <p className="text-dark fw-bold">{packageele.NoofDays} Tage <br></br> <strong className='td'>{packageele.StartingPrice}</strong> <br></br> Teilnehmer : Min {packageele.MinParticipants} - Max {packageele.MaxParticipants}</p>

                       </p>
                    </div>
                    
                      
                               </div>
                               <div className='card-footer'>
                    <Button className='gh position-absolute bottom-0 end-0 ' onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/package",
                                          search:packageele.id
                                        });
                                      
                                    }
                                }>DETAILS & BUCHEN</Button>
                    </div> 
                    </Card.Body>
                  </Card>
                     ))}
    </CardGroup>
       </div>
    );
  }
  
  export default Packages;