import ig from '../Images/insta.png';
import facebook from '../Images/fb.png';
import whatsapp from '../Images/wa.png';
import './footer.css';


function Footer() {
 


    return (
      <>
        
        <div className="footer">

            <div className='first'>
            <h3 id="logo" >Die Welt Erleben</h3>
            <div className="icons">
                <a href='https://www.facebook.com/profile.php?id=100063774111543' target="_blank"><img src={facebook} alt="facebook" height={30} /></a>
                <a href='https://www.instagram.com/diewelterlebenreisen/?igshid=ZDdkNTZiNTM%3D' target="_blank"><img src={ig} alt="instagram" height={30} /></a>
                <a href='https://api.whatsapp.com/send?phone=491637742954 ' target="_blank"><img src={whatsapp} alt="Whatsapp" height={30} /></a>
            </div>
            </div>

            <div className='second' style={{display: "flex", justifyContent: "center"}} >
                <div>
                <h3 className="subheading">QUICK LINKS</h3>
                   <a href="/team" className='text-light'><div style={{textAlign: "left"}}>Team</div></a> 
                   <a href="/sustainability" className='text-light' ><div style={{textAlign: "left"}}>Nachhaltigkeit</div></a> 
                   <a href="/enquiry" className='text-light' ><div style={{textAlign: "left"}}>Reise-Bausatz</div></a> 
                   <a href="/guid" className='text-light' > <div style={{textAlign: "left"}}>Reiseleiter</div></a>
                   </div>
            </div>
           
        
        </div>

        <div className="end text-light">
                 © 2023 "Die Welt erleben" - Reisen Alle Rechte vorbehalten  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <a href="/conditions" className='text-light fw-bold'>Reisebedingungen</a> (AGB)  <a href="/policies"className='text-light fw-bold' >Impressum</a> & <a href="/protection" className='text-light fw-bold'> Datenschutz</a>
        </div>


        </>
    );
  }
  
  export default Footer;