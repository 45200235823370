import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import { auth,db } from "./firebase-config";
import {useNavigate} from 'react-router-dom'
import './Home.css'
import { doc, updateDoc } from "firebase/firestore";

function Step1() {
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const [personalInfo, setPersonalInfo] = useState([{
        salutation: "",
        Surname: "",
        Lastname: "",
        StreetNo: "",
        POSTCODE: "",
        Locations: "",
        Country: "",
        contactnumber: 0,
        email: "",
        DateofBirth: "",
        Placeofbirth: "",
        Passportnumber: 0,
        Dateofissue: "",
        Validuntil: "",
        Placeofissue: "",
        Nationality: "",
        RoomArt: ""
    }])
    const nav = useNavigate();
    
   

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    const washingtonRef = doc(db,"Bookings", location.search.slice(1, 100));
    updateDoc(washingtonRef, {
        pers: personalInfo
      });
      nav({pathname: "/step2", search: location.search.slice(1, 100)});
  };

 



  
    return (
        <form className="m-4" validated={validated} onSubmit={handleSubmit}>
               <div>
       <button className="btn btn-secondary gh fw-bold m-5"
    onClick={
        (e) => {
            handleSubmit();
        }
}>Weiter</button> 
     </div>
 <p className="fs-4 td fw-bold text-start mx-4">Liebe Reisegäste</p>
 <p className="fs-6  fw-bold text-start mx-4">Felder mit * sind Pflichtfelder.
Titel, Vorname(n) und Nachname(n) wie im Reisepass angegeben.
</p>
                            <div>
                                <p className="fw-bold fs-5 text-start mx-4">Persönliche Informationen</p>
                                {
                                personalInfo.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {/* ....................................... */}

                                            <div className="row">
                                                <div className="col">
                                                    <label >Anrede*</label>
                                                    <select class="form-select" id="inputGroupSelect02" required
                                                        onChange={
                                                            (e) => {
                                                                const temp = [...personalInfo];
                                                                temp[index].salutation = e.target.value;
                                                                setPersonalInfo(temp);
                                                            }
                                                    }>
                                                        <option selected>Bitte wählen Sie...</option>
                                                        <option value="Frau">Frau
                                                        </option>
                                                        <option value="Herr">Herr</option>
                                                        <option value="Kein">Kein</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label>Vorname:*</label>
                                                    <input defaultValue={
                                                            item.Surname
                                                        }
                                                        onChange={
                                                            (e) => {
                                                                const temp = [...personalInfo];
                                                                temp[index].Surname = e.target.value;
                                                                setPersonalInfo(temp);
                                                            }
                                                        }
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="" required/>
                                                </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label>Nachname:*</label>
                                                <input defaultValue={
                                                        item.Lastname
                                                    }
                                                    onChange={
                                                        (e) => {
                                                            const temp = [...personalInfo];
                                                            temp[index].Lastname = e.target.value;
                                                            setPersonalInfo(temp);
                                                        }
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="" required/>
                                            </div>
                                        <div className="col">
                                            <label>Straße/Nr.:*</label>
                                            <input defaultValue={
                                                    item.StreetNo
                                                }
                                                onChange={
                                                    (e) => {
                                                        const temp = [...personalInfo];
                                                        temp[index].StreetNo = e.target.value;
                                                        setPersonalInfo(temp);
                                                    }
                                                }
                                                type="text"
                                                className="form-control"
                                                placeholder="" required/>
                                        </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>PLZ:*</label>
                                        <input defaultValue={
                                                item.POSTCODE
                                            }
                                            onChange={
                                                (e) => {
                                                    const temp = [...personalInfo];
                                                    temp[index].POSTCODE = e.target.value;
                                                    setPersonalInfo(temp);
                                                }
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder="" required/>
                                    </div>
                                <div className="col">
                                    <label>Standorte:*</label>
                                    <input defaultValue={
                                            item.Locations
                                        }
                                        onChange={
                                            (e) => {
                                                const temp = [...personalInfo];
                                                temp[index].Locations = e.target.value;
                                                setPersonalInfo(temp);
                                            }
                                        }
                                        type="text"
                                        className="form-control"
                                        placeholder="" required/>
                                </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Land:*</label>
                                <input defaultValue={
                                        item.Country
                                    }
                                    onChange={
                                        (e) => {
                                            const temp = [...personalInfo];
                                            temp[index].Country = e.target.value;
                                            setPersonalInfo(temp);
                                        }
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="" required/>
                            </div>
                        <div className="col">
                            <label>Telefon mobil Nr.: (z.B+49123XXX456)</label>
                            <input defaultValue={
                                    item.contactnumber
                                }
                                onChange={
                                    (e) => {
                                        const temp = [...personalInfo];
                                        temp[index].contactnumber = e.target.value;
                                        setPersonalInfo(temp);
                                    }
                                }
                                type="number"
                                className="form-control"
                                placeholder="" required/>
                        </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label>Email*:</label>
                        <input defaultValue={
                                item.email
                            }
                            onChange={
                                (e) => {
                                    const temp = [...personalInfo];
                                    temp[index].email = e.target.value;
                                    setPersonalInfo(temp);
                                }
                            }
                            type="email"
                            className="form-control"
                            placeholder="" required/>
                    </div>
                <div className="col">
                    <label>Geburtsdatum:* (TT.MM.JJJJ)</label>
                    <input defaultValue={
                            item.DateofBirth
                        }
                        onChange={
                            (e) => {
                                const temp = [...personalInfo];
                                temp[index].DateofBirth = e.target.value;
                                setPersonalInfo(temp);
                            }
                        }
                        type="date"
                        className="form-control"
                        placeholder="" required/>
                </div>
        </div>

        <div className="row">
            <div className="col">
                <label>Geburtsort:*</label>
                <input defaultValue={
                        item.Placeofbirth
                    }
                    onChange={
                        (e) => {
                            const temp = [...personalInfo];
                            temp[index].Placeofbirth = e.target.value;
                            setPersonalInfo(temp);
                        }
                    }
                    type="text"
                    className="form-control"
                    placeholder="" required/>
            </div>
    </div>

    <p>Reisepass Daten</p>

    <div className="row">
        <div className="col">
            <label>Reisepassnummer: *</label>
            <input defaultValue={
                    item.Passportnumber
                }
                onChange={
                    (e) => {
                        const temp = [...personalInfo];
                        temp[index].Passportnumber = e.target.value;
                        setPersonalInfo(temp);
                    }
                }
                type="text"
                className="form-control"
                placeholder="" required/>
        </div>
</div>

<div className="row">
    <div className="col">
        <label>Ausstellungsdatum: *</label>
        <input defaultValue={
                item.Dateofissue
            }
            onChange={
                (e) => {
                    const temp = [...personalInfo];
                    temp[index].Dateofissue = e.target.value;
                    setPersonalInfo(temp);
                }
            }
            type="date"
            className="form-control"
            placeholder="" required/>
    </div>
<div className="col">
    <label>Gültig bis:*</label>
    <input defaultValue={
            item.Validuntil
        }
        onChange={
            (e) => {
                const temp = [...personalInfo];
                temp[index].Validuntil = e.target.value;
                setPersonalInfo(temp);
            }
        }
        type="date"
        className="form-control"
        placeholder="" required/>
</div></div><div className="row"><div className="col">
<label>Ort der Ausstellung:	Ausstellungsort: *</label>
<input defaultValue={
        item.Placeofissue
    }
    onChange={
        (e) => {
            const temp = [...personalInfo];
            temp[index].Placeofissue = e.target.value;
            setPersonalInfo(temp);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Nationalität: *</label><input defaultValue={
        item.Nationality
    }
    onChange={
        (e) => {
            const temp = [...personalInfo];
            temp[index].Nationality = e.target.value;
            setPersonalInfo(temp);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div>{/* <div className="row"><div className="col"><label>Zimmertyp</label><select class="form-select" id="inputGroupSelect02"
    onChange={
        (e) => {
            const temp = [...personalInfo];
            temp[index].RoomArt = e.target.value;
            setPersonalInfo(temp);
        }
}><option selected>Bitte wählen Sie...</option><option value="1">einzel</option><option value="2">doppelt</option><option value="3">Zwilling</option></select></div>
</div> */}<br/> {/* .......................................................... */}{index > 0 ? <button onClick={
            () => {
                const temp = [...personalInfo];
                temp.splice(index, 1);
                setPersonalInfo(temp);
            }
        }
        className="btn btn-secondary m-1">löschen</button> : ""} </div>

                                    )
                                })
                            }
                                <button onClick={
                                        () => {
                                            const temp = [
                                                ...personalInfo, {
                                                    salutation: "",
                                                    Surname: "",
                                                    Lastname: "",
                                                    StreetNo: "",
                                                    POSTCODE: "",
                                                    Locations: "",
                                                    Country: "",
                                                    contactnumber: 0,
                                                    email: "",
                                                    DateofBirth: "",
                                                    Placeofbirth: "",
                                                    Passportnumber: 0,
                                                    Dateofissue: "",
                                                    Validuntil: "",
                                                    Placeofissue: "",
                                                    Nationality: "",
                                                    RoomArt: ""
                                                }
                                            ];
                                            setPersonalInfo(temp);
                                        }
                                    }
                                    className='col btn btn-secondary'>
                                    Weitere Personen hinzufügen</button>
                            </div>
        </form>
    );
}

export default Step1;
