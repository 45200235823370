import React, {useState} from 'react'
import {db} from './firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import './Home.css'

function Enquiry() {
    const [validated, setValidated] = useState(false);
    const [Asfor, setPAsfor] = useState("");
    const [Salutation, setSalutation] = useState("");
    const [Name, setName] = useState("");
    const [Surname, setSurname] = useState("");
    const [Street, setStreet] = useState("");
    const [Postcode, setPostcode] = useState("");
    const [Place, setPlace] = useState("");
    const [Country, setCountry] = useState("");
    const [Contactno, setContactno] = useState("");
    const [Nationality, setNationality] = useState("");
    const [Number4, setNumber4] = useState("");
    const [Travelfrom, setTravelfrom] = useState("");
    const [Travelto, setTravelto] = useState("");
    const [Destination, setDestination] = useState("");
    const [Number1, setNumber1] = useState("");
    const [Number2, setNumber2] = useState("");
    const [Number3, setNumber3] = useState("");
    const [Email, setEmail] = useState("");
    const [Number5, setNumber5] = useState("");
    const [Number6, setNumber6] = useState("");
    const [Number7, setNumber7] = useState("");
    const [Number8, setNumber8] = useState("");
    const [Number9, setNumber9] = useState("");
    const [bool1, setbool1] = useState("");
    const [bool2, setbool2] = useState("");
    const [bool3, setbool3] = useState("");
    const [bool4, setbool4] = useState("");
    const [bool5, setbool5] = useState("");
    const [bool6, setbool6] = useState("");

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
        setDoc(doc(db, "Enquiry", Name), {
            asfor: Asfor,
            name: Name,
            salutation: Salutation,
            surname: Surname,
            street: Street,
            postcode: Postcode,
            Place: Place,
            Country: Country,
            Contactno: Contactno,
            Nationality: Nationality,
            Travelfrom: Travelfrom,
            Travelto: Travelto,
            Destination: Destination,
            Email: Email,
            number1: Number1,
            number2: Number2,
            number3: Number3,
            Number4: Number4,
            number5: Number5,
            number6: Number6,
            number7: Number7,
            number8: Number8,
            number9: Number9,
            bool1: bool1,
            bool2: bool2,
            bool3: bool3,
            bool4: bool4,
            bool5: bool5,
            bool6: bool6
        });
        alert("Submited")
      };
    

    return (
        <div className="m-5 w-75 mx-auto">
            <h3 class="text-center mt-5 td">Reise-Bausatz</h3>
            <h3 className='text-center '>--------o--------</h3>
            <p className='text-center mb-5'>Anmeldung bei "Experience the World" - Travel für das Travel Kit</p>
            <h6 className='mb-3 text-start'>Felder, die mit * markiert sind, sind Pflichtfelder.</h6>
            <form validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <label>Art der Reise *</label>
                        <select class="form-select" id="inputGroupSelect02" required
                            onChange={
                                (e) => {
                                    setPAsfor(e.target.value);
                                }
                        } >
                            <option selected>Wählen......</option>
                            <option value="Group Tours">Gruppenreisen</option>
                            <option value="Individual and family travel">Individual- und Familienreisen</option>
                            <option value="Yoga retreats">Yoga-Retreats</option>
                            <option value="Ayurvedic tours">Ayurveda-Touren</option>
                            <option value="Other Trips">Andere Reisen</option>
                        </select>
                    </div>
                    <div className="col">
                        <label>Anrede*</label>
                        <select class="form-select" id="inputGroupSelect02" required
                            onChange={
                                (e) => {
                                    setSalutation(e.target.value);
                                }
                        }>
                            <option selected>Wählen......</option>
                            <option value="Frau">Frau
                            </option>
                            <option value="Herr">Herr</option>
                            <option value="Keine">Keine</option>
                        </select>
                    </div>
                    <div className="col">
                        <label>Name*</label>
                        <input onChange={
                                (e) => {
                                    setName(e.target.value);
                                }
                            }
                            type="text"
                            className="form-control"
                            placeholder="" required/>
                    </div>
            </div>
            <br/>
            <div className="row">
                <div className="col">
                    <label>Nachname*</label>
                    <input onChange={
                            (e) => {
                                setSurname(e.target.value);
                            }
                        }
                        type="text"
                        className="form-control"
                        placeholder="" required/>
                </div>
            <div className="col">
                <label>Straße, Nr.:*</label>
                <input onChange={
                        (e) => {
                            setStreet(e.target.value);
                        }
                    }
                    type="text"
                    className="form-control"
                    placeholder="" required/>
            </div>
        <div className="col">
            <label>Postleitzahl:*</label>
            <input onChange={
                    (e) => {
                        setPostcode(e.target.value);
                    }
                }
                type="text"
                className="form-control"
                placeholder="" required/>
        </div>
</div>
<br/>
<div className="row">
    <div className="col">
        <label>Ort:*</label>
        <input onChange={
                (e) => {
                    setPlace(e.target.value);
                }
            }
            type="text"
            className="form-control"
            placeholder="" required/>
    </div>
<div className="col">
    <label>Land*</label>
    <input onChange={
            (e) => {
                setCountry(e.target.value);
            }
        }
        type="text"
        className="form-control"
        placeholder="" required/>
</div><div className="col">
<label>Kontakt Nummer* mit Ländervorwahl (+49 12xxx89)
</label>
<input onChange={
        (e) => {
            setContactno(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><br/><div className="row"><div className="col"><label>Staatsangehörigkeit*</label><input onChange={
        (e) => {
            setNationality(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Email*</label><input onChange={
        (e) => {
            setEmail(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Reise von*</label><input onChange={
        (e) => {
            setTravelfrom(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><br/><div className="row"><div className="col"><label>Reise bis*</label><input onChange={
        (e) => {
            setTravelto(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Reiseziel*</label><input onChange={
        (e) => {
            setDestination(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Anzahl Reisende über 12 Jahre:</label><input onChange={
        (e) => {
            setNumber1(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><br/><div className="row"><label>Anzahl der Kinder nach Altersgruppen: (0-2 Jahre) </label><input onChange={
        (e) => {
            setNumber2(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div> <br/> <div className="row"><label>Anzahl der Kinder nach Altersgruppen: (2-5 Jahre) </label><input onChange={
        (e) => {
            setNumber3(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/> </div><br/><div className="row"> <label>Anzahl der Kinder nach Altersgruppen: (5-7 Jahre) </label> <input onChange={
        (e) => {
            setNumber4(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><br/><br/><div className="row"> <label>Anzahl der Kinder nach Altersgruppen: (8-12 Jahre) </label> <input onChange={
        (e) => {
            setNumber9(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><br/><div className="row"><label>Zimmertyp - Einzelzimmer</label><input onChange={
        (e) => {
            setNumber5(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><br/><div className="row"><label>Zimmertyp- Doppelzimmer</label><input onChange={
        (e) => {
            setNumber6(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><br/><div className="row"><div className="col"><label>Zimmertyp- Zweibettzimmer</label><input onChange={
        (e) => {
            setNumber7(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Doppelzimmer mit Zustellbett für Kinder</label><input onChange={
        (e) => {
            setNumber8(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><br/><div className="row"><div className="col"><label>Benötigen Sie einen Guide für Ihre gesamte Tour?*</label><input onChange={
        (e) => {
            setbool1(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div><div className="col"><label>Benötigen Sie einen Lokalführer, um Sehenswürdigkeiten zu besuchen?*</label><input onChange={
        (e) => {
            setbool2(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><br/><div className="row"><div className="col"><label>Eintrittsgelder zu Sehenswürdigkeiten werden bezahlt von: *</label><select class="form-select" id="inputGroupSelect02"
    onChange={
        (e) => {
            setbool3(e.target.value);
        }
}><option selected>Wählen...</option><option value="Self">Selbst</option><option value="Travel Agency">Reisebüro</option></select></div><div className="col"><label>Internationale Flüge werden ggf. gebucht von?*</label><select class="form-select" id="inputGroupSelect02"
    onChange={
        (e) => {
            setbool4(e.target.value);
        }
} required><option selected>Wählen...</option><option value="Self">Selbst</option><option value="Travel Agency">Reisebüro</option></select></div></div><br/><div className="row"><div className="col"><label>Zugtickets, falls erforderlich, werden gebucht von:?*</label><select class="form-select" id="inputGroupSelect02"
    onChange={
        (e) => {
            setbool5(e.target.value);
        }
} required><option selected>Wählen...</option><option value="Self">Selbst</option><option value="Travel Agency">Reisebüro</option></select></div><div className="col"><label>Worauf sollten wir bei Ihrer Reise besonders achten?*</label><input onChange={
        (e) => {
            setbool6(e.target.value);
        }
    }
    type="text"
    className="form-control"
    placeholder="" required/></div></div><div className=""><button   onClick={
        (e) => {
            handleSubmit();
        }
}
    className='btn btn-secondary m-5'>EINREICHEN</button></div><br/></form></div>
    );
}

export default Enquiry;
