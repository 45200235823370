import {useLocation} from "react-router-dom";
import {useEffect, useState, React} from "react";
import {db} from './firebase-config'
import {useNavigate} from 'react-router-dom'
import { doc, updateDoc } from "firebase/firestore";
import './Home.css'
import Image from 'react-bootstrap/Image'
import { Button, Modal } from 'react-bootstrap';
import whatsapp from '../Images/logo1.png';

function Step2() {
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const nav = useNavigate();
    const [agreed, setagreed] = useState("");
    const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false)
     nav({pathname: "/slip", search: location.search.slice(1, 100)});
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    const washingtonRef = doc(db,"Bookings", location.search.slice(1, 100));
    updateDoc(washingtonRef, {
        agreed: agreed
      });
  };

  



  
    return (
        <div className="m-4">
 <form validated={validated} onSubmit={handleSubmit}>
                            <p className="fs-4 td fw-bold">Bestätigen</p>
                            <div class="form-check w-25 mx-auto">
      <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required onChange={
                                                            (e) => {
                                                                if (e.target.checked) {
                                                                  setagreed("yes")
                                                                } else {
                                                                    setagreed("no")
                                                                }
                                                            }
                                                        }/>
      <label class="form-check-label" for="invalidCheck">
      Stimmen Sie den Reisebedingungen zu (<a href="/conditions">Reisebedingungen </a>)
      </label>
    </div>

                                <button className="btn btn-secondary gh fw-bold m-5"
                                    onClick={
                                        (e) => {
                                          setShowModal(true)
                                            handleSubmit();
                                        }
                                }>FERTIG VIELEN DANK</button>
                            </form>
                             <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Image className="navimage"
                        src={whatsapp}
                        alt="First slide"></Image></Modal.Title>
        </Modal.Header>
        <Modal.Body>You have been booked tour package succesfully , and We will contact you soon by mail.</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
          Einverstanden
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Step2;
