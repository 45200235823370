import {useLocation} from "react-router-dom";
import {useEffect,useState} from "react";
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import { auth } from "./firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import './Home.css'



function  Slip( ) {
  const [userdetails, setuserdetails] = useState([]);
   const location = useLocation();
   const [Countries, setCountries] = useState([]);
   const [st, setst] = useState(0);
   const [invoice, setInvoice] = useState({});
   useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Dfdf")
      //setuid(user.uid);
      setuserdetails(user); 
      console.log(user)
      } else {
       //setuid("");
      }
    });
  }, [auth.currentUser]);

const packagedata1 = async () => {
  
  const colRef = collection(db, "Bookings");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setCountries(docs)
        const user = Countries.filter(e=>e.id==location.search.slice(1,100))[0];
        console.log("user", user)
        setInvoice(user);
}
console.log(invoice)
console.log(invoice?.pers?.length)
const packagedata2 = async () => {
  try{
    console.log("mail sent to:", userdetails.email)
    const res = fetch("https://die-welt-erleben.onrender.com/mail",{
    //const res = await fetch("http://localhost:4000/mail", {
      method : "POST",
      mode : 'cors',
      headers :{
        'Content-Type': 'application/json'
      },
      body : JSON.stringify({
        to: userdetails.email,       //reciever address
        subject: "FixedGROW payment Reciept",  //subject of the mail
        text: "Payment successful",           //message 
        name : invoice.name,
        amount : invoice.amount,
        travelers : invoice?.pers?.length || 0
      })
    });
    //const data = await res.json();
    alert("email sent to your mail")
  }
  catch(err){
    console.log("errer", err);
  }
}



useEffect(() => {
  packagedata1();
  //packagedata2();
}, []);
    return (
       <div>
         <div class="card">
         {Countries.filter(e=>e.id==location.search.slice(1,100)).map(Countriesele => (
            <div>
              <div class="card-header bg-primary text-light fw-bold text-start">
                    {Countriesele.packagename}
                  </div>
              <div class="card-header bg-primary text-light fw-bold text-start">
              Ich bin zum Zeitpunkt der Reise vollständig gegen COVID-19 geimpft : {Countriesele.covid}
                  </div>
                  <div class="card-header bg-primary text-light fw-bold text-start">
                    {Countriesele.extraactivity}
                  </div>
                  <div class="card-header bg-primary text-light fw-bold text-start">
                    Package price {Countriesele.packageprice}
                  </div>
                  <div class="card-header bg-secondary text-light fw-bold text-start">
                    {Countriesele.condition}
                  </div>
                  <div class="card-header bg-secondary text-light fw-bold text-start">
                  Stimmen Sie den Reisebedingungen zu (With Link) : {Countriesele.agreed}
                  </div>
            {Countriesele.pers.map(Countriesele1 => {
              // setName(Countriesele1.Surname);
              // console.log("name", name)
              return(
            <div> 
                    <div class="card-header fw-bold fs-4 text-danger text-start">
                     Ihre Reiseinformationen
                  </div>
                  <div class="card-body">
                  <h5 class="card-title text-start">{Countriesele1.Surname} {Countriesele1.Lastname}</h5>
                  <p class="card-text text-start">{Countriesele1.contactnumber}</p>
                  <p class="card-text text-start">{Countriesele1.StreetNo},{Countriesele1.Locations},{Countriesele1.POSTCODE},{Countriesele1.Country}</p>
                  <p class="card-text text-start">Reisepass : {Countriesele1.Passportnumber}</p>
                  <p class="card-text text-start">Gültig ab: {Countriesele1.Dateofissue} | Gültig bis- {Countriesele1.Validuntil}</p>
                </div>
                </div>
                   )})}
                   <div class="card-header text-start td fs-4 fw-bold">
                   Gesamtpreis der Reise:  {Countriesele.amount}
                  </div>
                  <div>
                    <button className="btn btn-secondary mt-3 mb-3" onClick={packagedata2}>schicken</button>
                    </div>
                   </div>
              ))}
</div>
      </div>
    );
  }
  
  export default Slip;