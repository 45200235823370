import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import { db } from "./firebase-config";
import { doc,getDoc } from "firebase/firestore";

function Protection() {
   const [About, setAbout] = useState([]);

    useEffect(() => {
        Aboutdata();
    }, []);

    const Aboutdata = async () => {
        const docRef = doc(db, "otherpages","dataprotection");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setAbout(docSnap.data());
          } else {
          }
      }
    

    return (
       <div>
        <Card className='m-5'>
      <Card.Header className='m-3 fs-1'>Datenschutz</Card.Header>
      <h3 className='text-center'>----------o----------</h3>
      <Card.Title><div className='text-start m-5' dangerouslySetInnerHTML={{ __html:About.data}} />
      </Card.Title>
    </Card>
       </div>
    );
  }
  
  export default Protection;