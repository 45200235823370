import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import {db} from './firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import {useNavigate} from 'react-router-dom'
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase-config";
import './Home.css'

function Book() {
    const location = useLocation();
    const [extra, setextra] = useState(" ")
    const [validated, setValidated] = useState(false);
    const [Package, setpackage] = useState([]);
    const [condition, setcondition] = useState("");
    //const [Packagename23, setpackagename23] = useState("");
    var Packagename23 = "";
    const [roomtype, setroomtype] = useState("1");
    const [tra, settra] = useState("1");
    const [singlecharge, setsinglecharge] = useState("");
    const [price, setprice] = useState("");
    const [start, setstart] = useState("");
    const [end, setend] = useState("");
    const [ac, setac] = useState(0);
    const Id = Math.random().toString().slice(3, 6)
    const nav = useNavigate();
    const [userName, setuserName] = useState("");
    const [email, setemail] = useState("");
    const [uid, setuid] = useState("");
    const [covid, setcovid] = useState("Ja");
    const [veri, setveri] = useState("");
     const [sing, setsing] = useState(1);
   

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    if(roomtype==="3"){
        setcondition(": Wenn wir Ihren Reisepartner nicht finden könnten, müssen Sie den Einzelzimmer-Zuschlag zahlen. Bei Buchung eines halben Doppelzimmers: Sollte sich kein Zimmerpartner für Sie finden oder bei gemeinsamen Doppelzimmerbuchungen Ihr Zimmerpartner stornieren, müssen wir Ihnen bei dieser Reise den vollen Einzelzimmer-Zuschlag berechnen: Bei Buchung eines halben Doppelzimmers: Sollte sich kein Zimmerpartner/keine Zimmerpartnerin für Sie finden oder bei gemeinsamen Doppelzimmerbuchungen Ihr Zimmerpartner/Ihre Zimmerpartnerin stornieren, müssen wir Ihnen bei dieser Reise den vollen Einzelzimmer-Zuschlag berechnen.")
    }
    try {
        setDoc(doc(db, "Bookings", Id), {
            single: singlecharge,
            Price: price,
            Start: start,
            End: end,
            uid:uid,
            pending: true,
            id: Id,
            Roomtype: roomtype,
            covid:covid,
            bookingdate: new Date().getDate() + "-" + ( new Date().getMonth() + 1 ) + "-" + new Date().getFullYear(),
            packagename: Packagename23,
            extraactivity: extra,
            packageprice: tra + " " + "*" + price,
            name: userName,
            email: email,
            Traveler: tra,
            condition:condition,
            amount:  parseInt(sing) * parseInt(singlecharge) + parseInt(tra) * (parseInt(price)) + parseInt(ac)
        });
        alert("Die Welt Erleben hat Ihre Daten gespeichert ");
        nav({pathname: "/step1", search: Id});
    } catch (err) {
        console.log("Error to booking please try again leter", err);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setuid(user.uid);
            setuserName(user.displayName);
            setemail(user.email);
            if(user.emailVerified){
                setveri("yes")
              }
              else{
                setveri("no")
              }
        } else {}
    });
    packagedata1();
}, [auth.currentUser]);


const packagedata1 = async () => {
    const colRef = collection(db, "packages");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
    setpackage(docs);
}
  
    return (
        <div>
        {
            veri=== "yes" ? <div className="m-4">{
                Package.filter(e => e.id == location.search.slice(1, 100)).map((packageele, index) => {

                    Packagename23 = (packageele.packageName)
                    return(
                    //
                    //return
                    <>
                   <form key={index} validated={validated} onSubmit={handleSubmit}>
                   <div >
                   <div className="m-5">
                                                           <label className="td fw-bold text-start">Ich bin zum Zeitpunkt der Reise vollständig gegen COVID-19 geimpft:</label>
                                                           <select class="form-select" id="inputGroupSelect02" required
                                                               onChange={
                                                                   (e) => {
                                                                       setcovid(e.target.value);
                                                                   }
                                                           }>
                                                               <option value="Ja">Ja</option>
                                                               <option value="Nein">Nein</option>
                                                           </select>
                                                       </div>
                   </div>
                   {
                       covid==="Ja" ? <div>
               <div>
                                
                                               <div class="card">
                                                   <div class="card-header text-dark text-start">
                                                       Ihre gewünschte Reise
                                                   </div>
                                                   <div class="card-body">
                                                       <h5 class="card-title td text-start">
                                                           {
                                                           packageele.packageName
                                                       }</h5>
                                                   </div>
                                               </div>
                                               <div class="card">
                                                   <div class="card-header text-start">
                                                   Ihr gewünschter Termin
                                                   </div>
                                                   <div class="card-body">
                                                       <ul class="list-group">
                                                           {
                                                           packageele.price.map(ele1 => (
                                                               <li class="list-group-item">
                                                                   <input class="form-check-input me-1" type="radio" name="listGroupRadio" value="" required
                                                                       id={
                                                                           ele1.Departure
                                                                       }
                                                                       onChange={
                                                                           (e) => {
                                                                               setsinglecharge(ele1.Particularities);
                                                                               setprice(ele1.price);
                                                                               setend(ele1.TravelEnd);
                                                                               setstart(ele1.Departure);
                                                                           }
                                                                       }/>
                                                                   <label class="form-check-label w-100"
                                                                       for={
                                                                           ele1.Departure
                                                                   }>
                                                                       <div class="ms-2 me-auto">
                                                                           <div class="fw-bold">Einzelzimmerzuschlag : {
                                                                               ele1.Particularities
                                                                           }</div>
                                                                           <div className="fw-bold">
                                                                               Preis pro Person : {
                                                                               ele1.price
                                                                           } </div>
                                                                       </div>
                                                                       <span class="badge gh rounded-pill mt-3 mb-2 ">Von {
                                                                           ele1.Departure.slice(8, 10)
                                                                       }-{
                                                                           ele1.Departure.slice(5, 7)
                                                                       }-{
                                                                           ele1.Departure.slice(0, 4)
                                                                       } Bis {
                                                                           ele1.TravelEnd.slice(8, 10)
                                                                       }-{
                                                                           ele1.TravelEnd.slice(5, 7)
                                                                       }-{
                                                                           ele1.TravelEnd.slice(0, 4)
                                                                       }</span>
                                                                   </label>
                                                               </li>
                                                           ))
                                                       } </ul>
                                                   </div>
                                               </div>
                                               <div class="card">
                                                   <div class="card-header text-start">
                                                       Andere Aktivitäten
                                                   </div>
                                                   <div className="row">
                                                       <div className="col">
                                                           <label className="text-start">Zimmerart</label>
                                                           <select class="form-select" id="inputGroupSelect02" required
                                                               onChange={
                                                                   (e) => {
                                                                       
                                                                       setroomtype(e.target.value);
                                                                       //setpackagename23(packageele.packageName);
                                                                       if(e.target.value==="3"){
                                                                           settra(1);
                                                                            setsing(0);
                                                                       }
                                                                       else{
                                                                        if(e.target.value==="2"){
                                                                          setsing(0);
                                                                          settra(e.target.value)
                                                                        }
                                                                        else{
                                                                       settra(e.target.value);  
                                                                        }
                                                                          
                                                                       }
                                                                       
               
                                                                   }
                                                           }>
                                                               <option selected value="1">Einzelzimmer</option>
                                                               <option value="2">Doppelzimmer</option>
                                                               <option value="3">Doppelzimmer mit getrennten Betten</option>
                                                           </select>
                                                       </div>
                                                   </div>
                                                   <div class="card-body">
                                                       <ul class="list-group">
                                                           {
                                                               roomtype==="2" ?<div>
                                                                   {
                                                                    packageele.DepartureDetails.map((ele1, index) => (
                                                               <li class="list-group-item">
                                                                   <input class="form-check-input me-1" type="checkbox" value=""
                                                                       id={
                                                                           ele1.DepartureTitle
                                                                       }
                                                                       onChange={
                                                                           (e) => {
                                                                               if (e.target.checked) {
                                                                                   setac(parseInt(ac) + parseInt(ele1.DeparturePrice));
                                                                                   setextra(extra + (" " + ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice).toString())
               
                                                                               } else {
                                                                                   setac(parseInt(ac) - parseInt(ele1.DeparturePrice));
                                                                                   setextra(extra.replace(ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice, " "))
                                                                               }
                                                                           }
                                                                       }/>
                                                                   <label class="form-check-label text-dark w-100"
                                                                       for={
                                                                           ele1.DepartureTitle
                                                                   }>
                                                                       <div class="ms-2 me-auto">
                                                                           <div class="fw-bold">
                                                                               {
                                                                               ele1.DepartureTitle
                                                                           }</div>
                                                                           Preis : {
                                                                           ele1.DeparturePrice
                                                                       } </div>
                                                                   </label>
                                                               </li>
                                                           ))
                                                                   }
                                                                   {
                                                                        packageele.DepartureDetails.map((ele1, index) => (
                                                                           <li class="list-group-item">
                                                                               <input class="form-check-input me-1" type="checkbox" value=""
                                                                                   id={
                                                                                       ele1.DepartureTitle
                                                                                   }
                                                                                   onChange={
                                                                                       (e) => {
                                                                                           if (e.target.checked) {
                                                                                               setac(parseInt(ac) + parseInt(ele1.DeparturePrice));
                                                                                               setextra(extra + (" " + ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice).toString())
                           
                                                                                           } else {
                                                                                               setac(parseInt(ac) - parseInt(ele1.DeparturePrice));
                                                                                               setextra(extra.replace(ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice, " "))
                                                                                           }
                                                                                       }
                                                                                   }/>
                                                                               <label class="form-check-label text-dark w-100"
                                                                                   for={
                                                                                       ele1.DepartureTitle
                                                                               }>
                                                                                   <div class="ms-2 me-auto">
                                                                                       <div class="fw-bold">
                                                                                           {
                                                                                           ele1.DepartureTitle
                                                                                       }</div>
                                                                                       Preis : {
                                                                                       ele1.DeparturePrice
                                                                                   } </div>
                                                                               </label>
                                                                           </li>
                                                                       ))
                                                                   }
                                                               </div> :<div>
                                                                   {
                                                                   roomtype==="3" ? <div><div class="card-header text-dark text-start">
                                                                        <div class="form-check">
                     <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                     <label class="form-check-label" for="invalidCheck">
                     Condition Applied * : Wenn wir Ihren Reisepartner nicht finden könnten, müssen Sie den Einzelzimmer-Zuschlag zahlen. Bei Buchung eines halben Doppelzimmers: Sollte sich kein Zimmerpartner für Sie finden oder bei gemeinsamen Doppelzimmerbuchungen Ihr Zimmerpartner stornieren, müssen wir Ihnen bei dieser Reise den vollen Einzelzimmer-Zuschlag berechnen: Bei Buchung eines halben Doppelzimmers: Sollte sich kein Zimmerpartner/keine Zimmerpartnerin für Sie finden oder bei gemeinsamen Doppelzimmerbuchungen Ihr Zimmerpartner/Ihre Zimmerpartnerin stornieren, müssen wir Ihnen bei dieser Reise den vollen Einzelzimmer-Zuschlag berechnen.
                     </label>
                   </div>
                                                               </div></div> :" "
                                                                    }
                                                                   
                                                                   {
                                                                        packageele.DepartureDetails.map((ele1, index) => (
                                                                           <li class="list-group-item">
                                                                               <input class="form-check-input me-1" type="checkbox" value=""
                                                                                   id={
                                                                                       ele1.DepartureTitle
                                                                                   }
                                                                                   onChange={
                                                                                       (e) => {
                                                                                           if (e.target.checked) {
                                                                                               setac(parseInt(ac) + parseInt(ele1.DeparturePrice));
                                                                                               setextra(extra + (" " + ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice).toString())
                           
                                                                                           } else {
                                                                                               setac(parseInt(ac) - parseInt(ele1.DeparturePrice));
                                                                                               setextra(extra.replace(ele1.DepartureTitle + " " + ":" + " " + ele1.DeparturePrice, " "))
                                                                                           }
                                                                                       }
                                                                                   }/>
                                                                               <label class="form-check-label text-dark w-100"
                                                                                   for={
                                                                                       ele1.DepartureTitle
                                                                               }>
                                                                                   <div class="ms-2 me-auto">
                                                                                       <div class="fw-bold">
                                                                                           {
                                                                                           ele1.DepartureTitle
                                                                                       }</div>
                                                                                       Preis : {
                                                                                       ele1.DeparturePrice
                                                                                   } </div>
                                                                               </label>
                                                                           </li>
                                                                       ))
                                                                   }
                                                               </div>
                                                          
                                                       } </ul>
                                                   </div>
                                               </div>
                                           </div>
                                           <div>
                      <button className="btn btn-secondary gh fw-bold m-5"
                   onClick={
                       (e) => {
                           handleSubmit();
                       }
               }>Weiter</button> 
                    </div>
                       </div> :<div><p className="fw-bold fs-4 text-start mx-5">Mit vollständiger Impfung darf niemand außerhalb des Landes reisen" Entschuldigung für Ihre Unannehmlichkeiten</p></div>
                   }
                    </form>
                    </>
                    
                   
                )})}
                       </div> :<p className="text-start td fw-bold fs-4 m-5"><a href="/dashboard">Bestätigen Sie Ihre Email</a></p>
        }
        </div>
        
    );
}

export default Book;
