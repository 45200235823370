import {useEffect, useState} from "react";
import { db } from "./firebase-config";
import { doc,getDoc } from "firebase/firestore";
import './contact.css';

function Contact() {

    const [Contact, setContact] = useState([]);

    useEffect(() => {
        Contactdata();
    }, []);

    const Contactdata = async () => {
        const docRef = doc(db, "otherpages","contact");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setContact(docSnap.data());
          } else {
          }
      }

  return (
    <section id="contact" className="m-5">
    <div class="container">
        <h3 class="text-center td">KONTAKT</h3>
        <h3 className='text-center'>--------o--------</h3>
        <p class="text-center w-75 m-auto">Haben Sie Fragen zu unseren Reisen oder möchten Sie buchen? Wir sind persönlich für Sie da, um zu beraten! Schreiben Sie gerne eine Email oder rufen Sie uns an!</p>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 my-5">
            <div class="card border-0">
               <div class="card-body text-center">
                 <h4 class="td mb-2">KONTAKT </h4>
                 <p>{Contact.Mobile1}<br></br>{Contact.Mobile2}</p>
               </div>
             </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 my-5">
            <div class="card border-0">
               <div class="card-body text-center">
                 <h4 class="td mb-2">ADRESSE</h4>
                <p><div dangerouslySetInnerHTML={{ __html:Contact.Address}} /></p>
               </div>
             </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 my-5">
            <div class="card border-0">
               <div class="card-body text-center">
                 <h4 class="td mb-2">WEBSEITE</h4>
                 <h5><a href="https://diewelt-erleben.de">{Contact.Website}</a></h5>
               </div>
             </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 my-5">
            <div class="card border-0">
               <div class="card-body text-center">
                 <h4 class="td mb-2">EMAIL</h4>
                 <h5><a className="text-primary">{Contact.Email}</a></h5>
                 
               </div>
             </div>
          </div>
        </div>
    </div>
 </section>
  );
}

export default Contact;