import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAoXe6h9Uc_h1kv7SSLC-AOPN1FywBNfuA",
    authDomain: "die-welt-erleben.firebaseapp.com",
    projectId: "die-welt-erleben",
    storageBucket: "die-welt-erleben.appspot.com",
    messagingSenderId: "556427141724",
    appId: "1:556427141724:web:6340a4c70e838070b3bc99"
  };

  const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export {
    auth,
    storage,
    db
};