import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import {useEffect, useState} from "react";
import { db } from "./firebase-config";
import { doc,getDoc } from "firebase/firestore";
import './Home.css';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useNavigate} from 'react-router-dom'
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";


function Home() {
  const [slide3, setslide3] = useState([]);
  const nav = useNavigate();
  const [package1, setpackage1] = useState([]);


  useEffect(() => {
    fidata1();
    packagedata1();
}, []);

const packagedata1 = async () => {
  const colRef = collection(db, "packages");
  const snapshots = await getDocs(colRef);
  const docs = snapshots.docs.map(doc => doc.data());
      setpackage1(docs);
}


const fidata1 = async () => {
  const colRef = collection(db, "Slider");
  const snapshots = await getDocs(colRef);
  const docs = snapshots.docs.map(doc => doc.data());
      setslide3(docs);
}
  
  
  

  return (
   <div>
    <div className='w-75 mx-auto m-5'>
     <Carousel>
     {slide3.map(ele => (
      <Carousel.Item>
        <img
          className="d-block "
          src={ele.image}
          alt="Third slide"
        />

        <Carousel.Caption>
        <h3 className='fw-bold shadow-sm '>{ele.title}</h3>
          <p className='fw-bold shadow-sm '>{ele.subtitle}</p>
        </Carousel.Caption>
      </Carousel.Item>
     ))}
    </Carousel>
   </div>
   <div className='m-5'>
   <h1 className='text-center fg'>NEUE REISEN - INDIEN</h1>
    <h3 className='text-center'>----------------o----------------</h3>
    <p className='text-center gf fw-bold'>Erleben Sie die Welt mit uns</p>
   <CardGroup className='m-2'>
   {package1.filter(e=>e.ToCountry.toUpperCase()=="INDIEN").slice(0,3).map(packageele => ( 
                  <Card className='mx-3 '>
                  <Card.Img variant="top" className='in' src={packageele.uploadThumbURL} />
                  <Card.Body>
                   <a href=''><Card.Title className='text-start text-dark fw-bold' hrf='' onClick={
                                  (e) => {
                                      nav({
                                        pathname: "/package",
                                        search:packageele.id
                                      });
                                    
                                  }
                              }>{packageele.packageName}</Card.Title></a>
                              <div className=''>
                    <div className="mx-1 ">
                    <p className='text-danger text-start'>
                    <p className="text-dark fw-bold">{packageele.NoofDays} Tage <br></br> <strong className='td'>{packageele.StartingPrice}</strong> <br></br> Teilnehmer : Min {packageele.MinParticipants} - Max {packageele.MaxParticipants}</p>

                     </p>
                  </div>
                  
                    
                             </div>
                             <div className='card-footer'>
                  <Button className='gh position-absolute bottom-0 end-0 ' onClick={
                                  (e) => {
                                      nav({
                                        pathname: "/package",
                                        search:packageele.id
                                      });
                                    
                                  }
                              }>DETAILS & BUCHEN</Button>
                  </div> 
                  </Card.Body>
                </Card>
                     ))}
    </CardGroup>
   </div>
   <div className='m-5'>
   <h1 className='text-center fg'>BESTSELLER REISEN</h1>
    <h3 className='text-center'>----------------o----------------</h3>
    <p className='text-center gf fw-bold'>Erleben Sie die Welt mit uns</p>
   <CardGroup className='m-2'>
   {package1.slice(0,3).map(packageele => ( 
                  <Card className='mx-3 '>
                  <Card.Img variant="top" className='in' src={packageele.uploadThumbURL} />
                  <Card.Body>
                   <a href=''><Card.Title className='text-start text-dark fw-bold' hrf='' onClick={
                                  (e) => {
                                      nav({
                                        pathname: "/package",
                                        search:packageele.id
                                      });
                                    
                                  }
                              }>{packageele.packageName}</Card.Title></a>
                              <div className=''>
                    <div className="mx-1 ">
                    <p className='text-danger text-start'>
                    <p className="text-dark fw-bold">{packageele.NoofDays} Tage <br></br> <strong className='td'>{packageele.StartingPrice}</strong> <br></br> Teilnehmer : Min {packageele.MinParticipants} - Max {packageele.MaxParticipants}</p>

                     </p>
                  </div>
                  
                    
                             </div>
                             <div className='card-footer'>
                  <Button className='gh position-absolute bottom-0 end-0 ' onClick={
                                  (e) => {
                                      nav({
                                        pathname: "/package",
                                        search:packageele.id
                                      });
                                    
                                  }
                              }>DETAILS & BUCHEN</Button>
                  </div> 
                  </Card.Body>
                </Card>
                     ))}
    </CardGroup>
   </div>
   </div>
  );
}

export default Home;