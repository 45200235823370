import React from 'react';
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import './Home.css';
import Carousel from 'react-bootstrap/Carousel';
import {db} from './firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import ReCAPTCHA from 'react-google-recaptcha';


const Package = () => {
    const [captchaValue, setCaptchaValue] = useState(null);
    const location = useLocation();
    const [Package, setpackage] = useState([]);
    const [LoginEmail, setaLoginEmail] = useState("");
    const [packagename, setapackagename] = useState("");
    const [Name, setName] = useState("");
    const [Rew, setRew] = useState("");
    const [address, setaddress] = useState("");
    const [Contact, setContact] = useState("");
    const nav = useNavigate();


    useEffect(() => {
        packagedata1();
    }, []);

    const login = async (event) => {
        if (captchaValue) {
            console.log('Captcha verified:', captchaValue);
            // Process form data
        } else {
            alert('Please verify the captcha');
            return;
        }
        setDoc(doc(db, "Review", Name), {
            name: Name,
            email: LoginEmail,
            review: Rew,
            Address: address,
            contact: Contact,
            packkage: packagename
        });
        alert("Sent")
    };

    const packagedata1 = async () => {
        const colRef = collection(db, "packages");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setpackage(docs);
    }
    return (
        <div>
            <div> {
                Package.filter(e => e.id == location.search.slice(1, 100)).map(packageele => (
                    <div>
                        <div class="card  mt-5 mx-2">
                        <Carousel className='m-3'>
                                    {
                                    packageele.AccommodationDetails.map(ele => (
                                        <Carousel.Item>
                                            <img className="d-block"
                                                src={
                                                    ele.url
                                                }
                                                alt="First slide"/>
                                            <Carousel.Caption>
                                                <h3>{
                                                    ele.title
                                                }</h3>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))
                                } </Carousel>
                            <div class="table-responsive gh">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='text-light fs-5'>Teilnehmer: Min {
                                                packageele.MinParticipants
                                            } - Max {
                                                packageele.MaxParticipants
                                            }</th>
                                            <th scope="col" className='text-light fs-5'>Preise : {
                                                packageele.StartingPrice
                                            }</th>
                                            <th scope="col" className='text-light fs-5'>Dauer : {
                                                packageele.NoofDays
                                            } Tage</th>
                                            <th scope="col">
                                                <a href="" class="btn btn-light td fw-bold w-100"
                                                    onClick={
                                                        (e) => {
                                                            nav({pathname: "/book", search: packageele.id});

                                                        }
                                                }>BUCHEN</a>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="card-body">
                                <h5 class="card-title text-start fs-2">
                                    {
                                    packageele.packageName
                                }</h5>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold'>Highlights</p>
                                    <footer class="blockquote-footer text-start">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.Travelhighlightdescription}
                                            }/>
                                    </footer>
                                </blockquote>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold'>Reiseverlauf</p>
                                </blockquote>
                                <ol class="list-group list-group mt-4">
                                    {
                                    packageele.Itinerary.map(ele1 => (
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <span class="badge gh fs-6 rounded-pill">Tag {
                                                ele1.ItineraryDays
                                            }</span>
                                            <div class="ms-2 me-auto">
                                                <div class="fw-bold fs-6 text-start">
                                                    {
                                                    ele1.ItineraryTitle
                                                }</div>
                                                <div className='text-start'>{
                                                ele1.ItineraryDescription
                                            }</div>
                                                 </div>
                                        </li>
                                    ))
                                } </ol>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold mt-1'>Reiseleiter</p>
                                    <footer class="blockquote-footer">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.TourGuide}
                                            }/>
                                    </footer>
                                </blockquote>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold'>Reiseinformation</p>
                                    
                                    <footer class="blockquote-footer">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.TripInclusion}
                                            }/>
                                    </footer>
                                    
                                    <footer class="blockquote-footer">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.TripExclusion}
                                            }/>
                                    </footer>
                                    <p className='text-start fs-2 text-danger fw-bold'>Trinkgeld - Empfehlung</p>
                                    <footer class="blockquote-footer">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.TripRecommendation}
                                            }/>
                                    </footer>
                                </blockquote>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold'>Hotels, Namen und Übernachtungen</p>

                                    <footer class="blockquote-footer">
                                        <div className='text-start'
                                            dangerouslySetInnerHTML={
                                                {__html: packageele.Accommodation}
                                            }/>
                                    </footer>
                                </blockquote>
                                <blockquote class="blockquote mb-0">
                                    <p className='text-start fs-2 text-danger fw-bold'>Termine und Preise</p>
                                </blockquote>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Von</th>
                                                <th scope="col">Bis</th>
                                                <th scope="col">Einzelzimmerzuschlag</th>
                                                <th scope="col">Preise pro Person im DZ</th>
                                            </tr>
                                        </thead>
                                        <tbody> {
                                            packageele.price.map(ele1 => (
                                                <tr>
                                                    <td>{
                                                        ele1.Departure.slice(8, 10)
                                                    }-{
                                                        ele1.Departure.slice(5, 7)
                                                    }-{
                                                        ele1.Departure.slice(0, 4)
                                                    }</td>
                                                    <td>{
                                                        ele1.TravelEnd.slice(8, 10)
                                                    }-{
                                                        ele1.TravelEnd.slice(5, 7)
                                                    }-{
                                                        ele1.TravelEnd.slice(0, 4)
                                                    }</td>
                                                    <td> {
                                                        ele1.Particularities
                                                    }</td>
                                                    <td>{
                                                        ele1.price
                                                    }</td>
                                                    <td><a href="" class="btn btn-light td fw-bold w-100"
                                                    onClick={
                                                        (e) => {
                                                            nav({pathname: "/book", search: packageele.id});

                                                        }
                                                }>BUCHEN</a></td>
                                                </tr>
                                            ))
                                        } </tbody>
                                    </table>
                                </div>
                                <ul class="list-group list-group-horizontal">
                                    <li class="list-group-item w-100">
                                        <h5 className="text-start mx-5 mt-5">
                                            Schreiben Sie hier Ihre Bewertung.... <br></br>--------------------O--------------------</h5>
                                        <div className='row'>
                                            <div className="m-3 col">
                                                <label className="form-label text-start" >Name</label>
                                                <input type="text" className="form-control" id="exampleInputtext" aria-describedby="emailHelp"
                                                    onChange={
                                                        (e) => {
                                                            setName(e.target.value);
                                                            setapackagename(packageele.packageName)
                                                        }
                                                    }/>
                                            </div>
                                        <div className="m-3 col">
                                            <label className="form-label text-start" >Email</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                onChange={
                                                    (e) => {
                                                        setaLoginEmail(e.target.value);
                                                    }
                                                }/>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="m-3 col">
                                        <label className="form-label text-start" >Kontakt</label>
                                        <input type="text" className="form-control" id="exampleInputtext" aria-describedby="emailHelp"
                                            onChange={
                                                (e) => {
                                                    setContact(e.target.value);
                                                }
                                            }/>
                                    </div>
                                <div className="m-3 col">
                                    <label className="form-label text-start" >Adresse</label>
                                    <input type="text" className="form-control" id="exampleInputtext" aria-describedby="emailHelp"
                                        onChange={
                                            (e) => {
                                                setaddress(e.target.value);
                                            }
                                        }/>
                                </div>
                        </div>

                        <div className="m-3">
                            <textarea class="form-control" placeholder="Schreiben  Sie hier Ihre Bewertung" id="floatingTextarea"  onChange={
                                    (e) => {
                                        setRew(e.target.value);
                                    }
                                }></textarea>
                        </div>
                    <p className="m-3 text-white ">
                        <ReCAPTCHA   sitekey="6LdmRa4kAAAAAAOSdzoCSRqDCmkts9tVSMR7Q5Ky" hl='de'
                            onChange={setCaptchaValue}/>
                    </p>
                    <button type="submit" className="btn gh m-3 fw-bold"
                        onClick={login}>SENDEN</button>
                </li>
            </ul>
        </div>
    </div>
</div>
                ))
            } </div>
        </div>
    );
}

export default Package;
