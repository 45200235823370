import {useEffect, useState} from "react";
import {db} from "./firebase-config";
import { collection } from "firebase/firestore";
import {getDocs, } from "firebase/firestore";
import './contact.css';

function Team() {
    const [Team1, setTeam1] = useState([]);

    useEffect(() => {
        Teamdata1();
    }, []);

    const Teamdata1 = async () => {
        const colRef = collection(db, "Team");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
            setTeam1(docs);
    }
    return (
        <div className="m-5">
            <h1 className="text-center td">Team</h1>
            <h3 className='text-center mb-5'>-------o--------</h3>
            <div>
            {Team1.map(Teamele => ( 
                   <div class="card m-5">
                   <div class="row g-0">
                       <div class="col-md-4">
                           <img src={
                                   Teamele.image
                               }
                               class="img-fluid rounded-start"
                               alt="..."/>
                       </div>
                       <div class="col-md-8">
                           <div class="card-body">
                               <h5 class="card-title">
                                   {
                                    <div dangerouslySetInnerHTML={{ __html:Teamele.name}} />
                                  
                               }</h5>
                               <p class="card-text">
                                   {
                                    <div dangerouslySetInnerHTML={{ __html:Teamele.subtitle}} />
                                   
                               }</p>
                           </div>
                       </div>
                   </div>
               </div>
                     ))}
            </div>
        </div>
    );
}

export default Team;
