import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useNavigate} from 'react-router-dom'
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import './Home.css'
import { getAuth, sendEmailVerification } from "firebase/auth";

function Dashboard() {
  const auth = getAuth();
   const location = useLocation();
   const [Packagestoweb, setPackagestoweb] = useState([]);
   const [name, setname] = useState('');
   const [veri, setveri] = useState('');
   const nav = useNavigate();


  useEffect(() => {
    const user = auth.currentUser;
if (user !== null) {
  setname(user.displayName);
  if(user.emailVerified){
    setveri("yes")
  }
  else{
    setveri("no")
  }
 
}
    packagedata1();
}, []);

const packagedata1 = async () => {
  const colRef = collection(db, "Bookings");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map(doc => doc.data());
      setPackagestoweb(docs);
}
const packagedata2 = async () => {
  sendEmailVerification(auth.currentUser)
  .then(() => {
    alert("verification email sent to registered email")
  });
}


    return (
       <div className="m-5">
        <p className="text-start td fw-bold fs-4 mx-4">Name : {name}</p>
        <p className="text-start td fw-bold fs-4 mx-4"> Email bestätigt : {veri} <Button className='btn-dark'onClick={packagedata2} >Bestätigen Sie Ihre Email</Button></p>
       <CardGroup >
       <p className="text-start td fw-bold fs-4 mx-4"> Ihre Buchung</p>
   {Packagestoweb.filter(e=>e.uid==location.search.slice(1,200)).map(packageele => ( 
                    <Card className='mx-3 '>
                    <Card.Body>
                     <a href=''><Card.Title className='text-start text-dark fw-bold' >{packageele.packagename}</Card.Title></a>
                                <div className=''>
                      <div className="mx-1 ">
                      <p className='text-danger text-start'>
                      <p className="text-dark fw-bold">Booking date : {packageele.bookingdate}</p>
                       </p>
                    </div>
                    <div className='card-footer'>
                    <Button className='gh position-absolute bottom-0 end-0 ' onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/slip",
                                          search:packageele.id
                                        });
                                      
                                    }
                                }>Go to see</Button>
                    </div> 
                               </div>
                    </Card.Body>
                  </Card>
                     ))}
    </CardGroup>
       </div>
    );
  }
  
  export default Dashboard;