import {React, useEffect, useState} from "react";


const Wallet = (props) => {
    const {wallet} = props;
   


    return (
        <div className="mt-5 mb-5 ">
            <div className="card mt-5  mx-auto w-50 bg-secondary mb-5">
                {
                    wallet.length===0 ?<div className="text-light">Verzeihung ! Sie sind kein Agent.........</div>  :
                   
                     <div className="card-body">
                    <h6 className="card-title text-center text-white ">
                        Name :
                        {
                        wallet.name
                    }</h6>
                     <h6 className="card-title text-center text-white ">
                        Code :
                        {
                        wallet.Code
                    }</h6>
                    <h6 className="card-title text-center text-white ">
                        Bezeichnung :
                        {
                        wallet.Designation
                    }</h6>
                    <h6 className="card-title text-center text-white ">
                        Email :
                        {
                        wallet.email
                    }</h6>
                    <p className="card-title text-center text-warning fs-6">Wallet-Betrag : {
                        wallet.amount
                    }</p>
                </div>
            } </div>
        </div>
    )
}

export default Wallet;
