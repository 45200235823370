import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useState, useEffect} from "react";
import Navbar1 from './components/Navbar1';
import Home from './components/Home';
import { Route, Routes, BrowserRouter  } from 'react-router-dom';
import Footer from './components/Footer'
import Contact from './components/Contact';
import About from './components/About';
import Team from './components/Team';
import SUSTAINABILITY from './components/SUSTAINABILITY';
import Enquiry from './components/Enquiry';
import Regster from './components/Regster';
import Login from './components/Login';
import Agent from './components/Agent'
import Package from './components/Package';
import Packages from './components/Packages';
import Country from './components/Country';
import Guids from './components/Guids';
import Policies from './components/Policies';
import Conditions from './components/Conditions';
import Book1 from './components/Book1';
import Slip from './components/Slip';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth} from "./components/firebase-config";
import Wallet from './components/Wallet';
import Protection from './components/Protection';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Dashboard from './components/Dashboard';


function App() {
  const [ypt, setypt] = useState(false);
  const [wallet, setwallet] = useState([]);
  

  

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
       
        setypt(true);
      } else {
        
        setypt(false);
      }
    });
  }, [auth.currentUser]);
  
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar1  setwallet={setwallet}/>
        <Routes>
          <Route path='/' element={ <div><Home /><Footer /></div>}></Route>
          <Route path='/contact' element={ <div><Contact /><Footer /></div>}></Route>
          <Route path='/about' element={ <div><About /><Footer /></div>}></Route>
          <Route path='/team' element={ <div><Team /><Footer /></div>}></Route>
          <Route path='/enquiry' element={ <div><Enquiry /><Footer /></div>}></Route>
          <Route path='/register' element={ <div><Regster /><Footer /></div>}></Route>
          <Route path='/login' element={ <div><Login /><Footer /></div>}></Route>
          <Route path='/agent' element={ <div><Agent /><Footer /></div>}></Route>
          <Route path='/package' element={ <div><Package/><Footer /></div>}></Route>
          <Route path='/policies' element={ <div><Policies /><Footer /></div>}></Route>
          <Route path='/conditions' element={ <div><Conditions /><Footer /></div>}></Route>
          <Route path='/guid' element={ <div><Guids /><Footer /></div>}></Route>
          <Route path='/packages' element={ <div><Packages/><Footer /></div>}></Route>
          <Route path='/book' element={ <div>{
            ypt ?<div><Book1/><Footer /></div>:<div><Login/><Footer /></div>
          }</div>}></Route>
          <Route path='/wallet' element={ <div>{
            ypt ?<div><Wallet wallet={wallet} setwallet={setwallet} /><Footer /></div>:<div><Login/><Footer /></div>
          }</div>}></Route>
          <Route path='/slip' element={ <div><Slip/><Footer /></div>}></Route>
          <Route path='/step1' element={ <div><Step1/><Footer /></div>}></Route>
          <Route path='/step2' element={ <div><Step2/><Footer /></div>}></Route>
          <Route path='/country' element={ <div><Country /><Footer /></div>}></Route>
          <Route path='/dashboard' element={ <div><Dashboard /><Footer /></div>}></Route>
          <Route path='/protection' element={ <div><Protection/><Footer /></div>}></Route>
          <Route path='/sustainability' element={ <div><SUSTAINABILITY/><Footer /></div>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;