import {useEffect,useState} from "react";
import { db } from "./firebase-config";
import { collection } from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import './Home.css'
import Card from 'react-bootstrap/Card';

function Guids( ) {
    const [Guids, setGuids] = useState([]);
    const [search, setsearch] = useState("Indien");


  useEffect(() => {
    packagedata1();
}, []);

const packagedata1 = async () => {
    const colRef = collection(db, "Guids");
    const snapshots = await getDocs(colRef);
    const docs = snapshots.docs.map(doc => doc.data());
        setGuids(docs);
}

const Search = async () => {
    setsearch(search)
}


    return (
       <div>
         <Card className='m-5'>
         <Card.Header className='m-3 fs-1 td'>Reiseleiter</Card.Header>
         <h3 className='text-center'>----------o----------</h3>
         </Card>
        <div className="m-3 w-75 mx-auto">
                  <label className="form-label text-white">Land</label>
                  <input type="text" className="form-control" id="exampleInputtext" aria-describedby="emailHelp"
                      onChange={
                          (e) => {
                              setsearch(e.target.value);
                          }
                      }/>
              </div>
              <button type="submit" className="btn btn-dark m-3"
          onClick={Search}>Suchen</button>
       {Guids.filter(e=>e.country.toUpperCase()==search.toUpperCase()).map(Guidsele => ( 
                    <div class="card   m-5">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img src={
                                    Guidsele.image
                                }
                                class="img-fluid rounded-start"
                                alt="..."/>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">
                                    {
                                        <div dangerouslySetInnerHTML={{ __html:Guidsele.name}} />
                                    
                                }</h5>
                                <h5 class="card-title">
                                    {
                                        <div dangerouslySetInnerHTML={{ __html:Guidsele.country}} />
                                    
                                }</h5>
                                <p class="card-text">
                                    {
                                        <div dangerouslySetInnerHTML={{ __html:Guidsele.subtitle}} />
                                    
                                }</p>
                            </div>
                        </div>
                    </div>
                </div>
                     ))}
      </div>
    );
  }
  
  export default Guids;