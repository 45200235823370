import {useLocation} from "react-router-dom";
import {useEffect,useState} from "react";
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";

function Country( ) {
   const location = useLocation();
   const [Countries, setCountries] = useState([]);
   


  useEffect(() => {
    packagedata1();
}, []);

const packagedata1 = async () => {
  const colRef = collection(db, "Countries");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setCountries(docs)
}


    return (
       <div>
       {Countries.filter(e=>e.Country==location.search.slice(1,100)).map(Countriesele => ( 
                    <div class="card w-75 mx-auto">
                    <div class="card-header">
                      {Countriesele.Country}
                    </div>
                    <div class="card-body">
                      <p class="card-text"><div dangerouslySetInnerHTML={{ __html:Countriesele.information}} /></p>
                    </div>
                  </div>
                     ))}
      </div>
    );
  }
  
  export default Country;