import React from "react";
import {useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from './firebase-config';
import {updateProfile} from "firebase/auth";
import {db} from './firebase-config'
import {setDoc, doc} from 'firebase/firestore'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

function Agent() {
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [LoginName, setaLoginName] = useState("");
    const [code, setacode] = useState("");
    const [Phone, setphone] = useState("");
    const [Lno, setLno] = useState("");
    const [Web, setWeb] = useState("");
    const [Des, setDes] = useState("");
    const [cname, setcname] = useState("");
    const [adr, setadr] = useState("");
    const [pc, setpc] = useState("");
    const [ci, setci] = useState("");
    const [st, setst] = useState("");
    const [co, setco] = useState("");
    const [sy, setsy] = useState("");
    const nav = useNavigate();
    const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
        createUserWithEmailAndPassword(auth, Email, Password).then((userCredential) => { // Signed in
            const user = userCredential.user;
            alert("user registered successfully")
            updateProfile(auth.currentUser, {
                displayName: LoginName
            }).then(() => {
            }).catch((error) => {});
            setDoc(doc(db, "Agent", user.uid), {
                uid:user.uid,
                name:LoginName,
                Password:Password,
                email:Email,
                phone:Phone,
                Code:code,
                type:"Agent",
                landlineno:Lno,
                website:Web,
                Designation:Des,
                Company:cname,
                address:adr,
                pincode:pc,
                city:ci,
                state:st,
                country:co
                });
            nav("/");
        }).catch((error) => {
            const errorCode = error.code;
            setsy(errorCode);
        });
  };

    const signup = async (event) => {
        
    };

    return (
        <div className="mt-5 mb-5">
             <Form noValidate validated={validated} onSubmit={handleSubmit}  className=" mx-auto w-50 mt-5 bg-secondary  mb-32   border border-dark border border-3 border-opacity-10 rounded">
             <h5 className="text-center m-3 text-white">Anmeldeformular für Agenten</h5>
      <Row className="m-4">
        <Form.Group as={Col} md="4" controlId="validationCustom01"  onChange={
                        (e) => {
                            setaLoginName(e.target.value);
                        }
                    }>
          <Form.Label className="text-light">name</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02" onChange={
                        (e) => {
                            setphone(e.target.value);
                        }
                    }>
          <Form.Label className="text-light">Mobilnummer</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01" onChange={
                            (e) => {
                                setLno(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Festnetznummer</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03"  onChange={
                            (e) => {
                                setWeb(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Webseite</Form.Label>
          <Form.Control type="text"  required />
          <Form.Control.Feedback type="invalid">
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom04" onChange={
                            (e) => {
                                setcname(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Name der Agentur/Firma</Form.Label>
          <Form.Control type="text" placeholder="State" required />
          <Form.Control.Feedback type="invalid">
           
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom05" onChange={
                            (e) => {
                                setpc(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Postleitzahl</Form.Label>
          <Form.Control type="text" placeholder="Zip" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="m-4">
        <Form.Group as={Col} md="4" controlId="validationCustom01"  onChange={
                            (e) => {
                                setDes(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">stellenbezeichnung</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02"  onChange={
                            (e) => {
                                setEmail(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Email</Form.Label>
          <Form.Control
            required
            type="email"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01" onChange={
                        (e) => {
                            setPassword(e.target.value);
                        }
                    }>
          <Form.Label className="text-light">Passwort</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="m-4">
        <Form.Group as={Col} md="4" controlId="validationCustom01" onChange={
                            (e) => {
                                setadr(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Straße und Hausnummer</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02"  onChange={
                            (e) => {
                                setci(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Stadt</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01" onChange={
                            (e) => {
                                setst(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Bundesland</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="m-4">
        <Form.Group as={Col} md="4" controlId="validationCustom01"  onChange={
                            (e) => {
                                setco(e.target.value);
                            }
                        }>
          <Form.Label className="text-light">Land</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02" onChange={
                        (e) => {
                            setacode(e.target.value);
                        }
                    }>
          <Form.Label className="text-light">Agentencode</Form.Label>
          <Form.Control
            required
            type="text"
          />
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
      </Row>
     
      <p className="m-3 text-warning">
            {sy} </p>
        <p className="m-3 text-white">Wenn Sie ein Konto haben :
            <Link to="/login" className=" text-warning"> Einloggen</Link>
        </p>
        <button type="submit" className="btn btn-dark m-3"
            onClick={handleSubmit}>Registrieren</button>
    </Form>
</div>
    );
  }
  
  export default Agent;