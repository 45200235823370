import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import {useEffect, useState} from "react";
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import Image from 'react-bootstrap/Image'
import whatsapp from '../Images/logo1.png';
import './Home.css'
import { auth} from "./firebase-config";
import {signOut, onAuthStateChanged} from "firebase/auth";
import {useNavigate} from 'react-router-dom'
import { doc,getDoc } from "firebase/firestore";

function Navbar1({setwallet}) {
    const nav = useNavigate();
    const [destinations1, setdestinations1] = useState([]);
    const [ya, setya] = useState(false);
    const [st, setst] = useState("");
    const [uid, setuid] = useState("gfhjvjkbjhbh");
    const [destinations2, setdestinations2] = useState([]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setya(true);
                setuid(user.uid);
                setst(user.displayName.split(' '));

            } else {
                setya(false);
                setuid("");
            }
        });
        fidata();
        destinationsdata1();
        destinationsdata2();
    }, [auth.currentUser]);


    const destinationsdata1 = async () => {
        const colRef = collection(db, "Destinations");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setdestinations1(docs);
    }


    const destinationsdata2 = async () => {
        const colRef = collection(db, "Countries");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setdestinations2(docs)
    }

    const logout = async () => {
        signOut(auth).then(() => {
            alert("Successfully logout");
        }).catch((error) => {});
    };

    const fidata = async () => {
        const docRef = doc(db, "Agent",uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setwallet(docSnap.data());
          } else {
          }
      }



    return (
        <Navbar bg="white" variant="white" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <Image className="navimage"
                        src={whatsapp}
                        alt="First slide"></Image>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/"></Nav.Link>
                    </Nav>
                    <Nav>
                    <NavDropdown className='fw-bold text-dark' title="REISEZIELE" id="basic-nav-dropdown">
                            {
                            destinations1.map(destinations1ele => (
                                <NavDropdown.Item onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/packages",
                                          search:destinations1ele.Country
                                        });
                                      
                                    }
                                }>
                                    {
                                    destinations1ele.Country
                                }</NavDropdown.Item>
                            ))
                        } </NavDropdown>
                        <Nav.Link href="/about" className='fw-bold'>ÜBER UNS</Nav.Link>
                        <NavDropdown className='fw-bold' title="LÄNDERINFORMATION" id="basic-nav-dropdown">
                            {
                            destinations2.map(destinations2ele => (
                                <NavDropdown.Item onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/country",
                                          search:destinations2ele.Country
                                        });
                                      
                                    }
                                }>
                                    {
                                    destinations2ele.Country
                                }</NavDropdown.Item>
                            ))
                        } </NavDropdown>
                        <Nav.Link className='fw-bold' href="/contact">KONTAKT</Nav.Link>
                    </Nav>
                    <> {
                            ya ? (
                                <NavDropdown className='btn btn-light bg-white border-white text-danger font-bold mx-2 fw-bold rounded-circle ' title={st[0]} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={
                                    (e) => {
                                        nav({
                                          pathname: "/dashboard",
                                          search:uid
                                        });
                                      
                                    }
                                } >Ihr Account</NavDropdown.Item>
                                    <NavDropdown.Item ><button type="button" className="btn btn-light bg-white border-white text-danger font-bold fw-bold  "
                                    onClick={logout}>
                                    Log Out</button></NavDropdown.Item>
                            </NavDropdown>

                            ) : <Button variant="outline-success"className='mx-2 fw-bold' href='/login'>LOG IN</Button>
                        } </>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navbar1;
