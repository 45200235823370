import Card from 'react-bootstrap/Card';
import {useEffect,useState} from "react";
import {db} from "./firebase-config";
import {collection} from "firebase/firestore";
import {getDocs} from "firebase/firestore";
import './Home.css'
import Carousel from 'react-bootstrap/Carousel';

function SUSTAINABILITY() {
    const [Countries1, setCountries1] = useState([]);
    const [Countries2, setCountries2] = useState([]);


  useEffect(() => {
    packagedata1();
    packagedata2();
}, []);

const packagedata1 = async () => {
  const colRef = collection(db, "sustainability");
        const snapshots = await getDocs(colRef);
        const docs = snapshots.docs.map(doc => doc.data());
        setCountries1(docs)
}
const packagedata2 = async () => {
    const colRef = collection(db, "sustg");
          const snapshots = await getDocs(colRef);
          const docs = snapshots.docs.map(doc => doc.data());
          setCountries2(docs)
  }

    return (
        <div>
            <Card className='m-5'>
                <Card.Header className='m-3 fs-1 td'>NACHHALTIGKEIT</Card.Header>
                <h3 className='text-center'>--------o--------</h3>
                <div>
                <Carousel className='m-3'>
                                    {
                                    Countries2.map(ele => (
                                        <Carousel.Item>
                                            <img className="d-block"
                                                src={
                                                    ele.image
                                                }
                                                alt="First slide"/>
                                            <Carousel.Caption>
                                                <h3>{
                                                    ele.title
                                                }</h3>
                                                <p className='fw-bold shadow-sm '>{ele.subtitle}</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))
                                } </Carousel>
                </div>
                {Countries1.map(Countriesele => ( 
                    <Card.Title className='text-start m-4'>
                     <div dangerouslySetInnerHTML={{ __html:Countriesele.data}} />
                    </Card.Title>
                ))}
                <Card.Body>
                    
                    </Card.Body>
            </Card>
        </div>
    );
}

export default SUSTAINABILITY;
